.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: transparent;
}

.App-link {
  color: #61dafb;

}

h5 {
  font-weight: 100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.resume-cta {
  height: 100%;

}

h2 {
  font-size: 55px;



}

@media(max-width:600px) {
  .App-header {
    display: flex;
    flex-direction: column;
    min-height: 400px;

    background-color: transparent;
  }

  h2 {
    display:none;
  }
}