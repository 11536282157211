.social-links-home {
  display: none;
}

.home-bg-img {
  background-image: url("../img/alone-penguin.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  min-height: 100vh;

  /* width: 1600px; */
}

h1 {
  font-size: 65px;
}

.homepage-ctr {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 85px;
  margin-left: 170px;
  min-height: 100vh;
}

.name-title-wrap {
  margin-bottom: 15px;
  text-align: left;
  text-shadow: 1px 1px 1px #09868b;
}

h3.dev-title {
  font-size: 36px;
  margin-top: -25px;
  text-shadow: 0.5px 0.5px 0.5px black;
  font-weight: 400;
}

.quote-ctr {
  width: 500px;
  margin-left: 155px;
  font-weight: normal;
}

.info-modal {
  display: none;
  position: fixed;
  border: 3px double #09868b;
  background-color: white;
  border-radius: 7px;
  width: 45%;
  margin-top: -135px;
  margin-left: -65px;
  padding-bottom: 45px;
  padding-top: 100px;
  z-index: 2;
  /* This set the layer level of the element, it has precendence over initial level of 1*/

  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.info-modal--open {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 45%;
  max-height: 80vh;
  padding-right: 35px;
  padding-left: 35px;
  padding-bottom: 45px;
  margin-bottom: 45px;
  padding-top: 35px;
  overflow: auto;
}

.modal-title {
  margin-top: -15px;
  font-size: 45px;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
}

.close-btn {
  margin-top: 15px;
  margin-left: -21px;
  width: 35px;
  height: 35px;
  font-size: 15px;
  border: 1px solid #09868b;
  box-shadow: none;
  position: relative;
}

.close-btn:hover {
  border: 1px outset #09868b;
  box-shadow: none;
  font-size: 20px;
  cursor: pointer;
}

.close-btn:focus {
  border: 1px inset #09868b;
}

.close-btn:active {
  font-style: normal;
  color: black;
  border: 1px inset #09868b;
}

button {
  height: 45px;
  width: 180px;
  font-size: 18px;
  margin-left: 25px;
  border: 2px solid #09868b;
  border-radius: 5px;
  /* -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; */

  font-weight: 700;
}

button:hover {
  border: 2px groove black;
  /* -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777; */
  color: #09868b;
  font-weight: 600;
  cursor: pointer;
}

button:active {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}

button:focus {
  border: none;
  color: #09868b;
}

@media (max-width: 1200px) { 


}
@media (max-width: 900px) {
  .home-bg-img {
    background: linear-gradient(to bottom, white, #76c1d4, white);
  }

  .homepage-ctr {
    margin-right: 155px;
    margin-top: 55px;
  }

  h1 {
    font-size: 54px;
    text-align: center;
  }

  .quote-ctr {
    width: 500px;
    margin-left: 155px;
    font-size: 24px;
  }

  .quote-generator {
    margin-left: -5px;
    margin-right: 85px;
  }

  .dev-title {
    width: 475px;
    font-size: 18px;
    text-align: center;
    color: black;
  }

  .quote-btn-ctr {
    margin-left: 0px;
  }

  .modal-title {
    font-size: 35px;
  }

  .info-modal--open {
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
  }
  
}

/* End media 900px  */

@media (max-width: 750px) {
  .social-links-home {
    display: flex;
    margin-top: 35px;
  }

  img.nav-icon {
    height: 45px;
    border-radius: 9px;
    /* -webkit-box-shadow: 0 10px 6px -6px #777;
        -moz-box-shadow: 0 10px 6px -6px #777;
        box-shadow: 8px 7px 5px -5px #777; */
  }

  img.email {
    margin-left: 55px;
  }

  img.linkedin {
    margin-right: 55px;
  }

  .quote-ctr {
    margin-left: 35px;
    padding-left: 15px;
  }

  h5 {
    font-size: 18px;
  }

  .quote-generator {
    margin-right: 5px;
    margin-left: 25px;
  }

  button.close-btn {
    margin-top: 15px;
    margin-left: -20px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 45px;
    margin: 0 auto;
    width: 100%;
  }



  button {
    border: 1px solid black;
  }

  img.nav-icon {
    box-shadow: 2px 2px 8px -1px white;
    /* border: 1px solid black; */
  }

  img.nav-icon.linkedin {
    border: none;
  }

  h3.dev-title {
    font-size: 31px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    font-weight: 100;
    color: black;
    text-shadow: 0.5px 0.5px 0.5px #09868b;
    width: 375px;
    text-align: center;
  }

  button.close-btn {
    height: 25px;
    width: 25px;
    font-size: 12px;
    margin-top: -25px;
  }

  .quote-ctr {
    margin-left: -45px;
  }

  .quote-btn-ctr {
    margin: 0 auto;
    width: 180px;
  }

  .quote-btn {
    display: none;
  }

  .name-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 45px;
  }

.homepage-ctr {
    width: fit-content;
    min-height: 500px;
    max-height: 100vh;
    justify-content: top;
    /* margin-left:170px; */
    margin-right: auto;
    margin-left: auto;

  }

  .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
  }

  button {
    font-size: 21px;
    margin: 0 auto;
  }

  .social-links-home {
    display: flex;
    margin-top: 35px;
  }

  h3.modal-title {
    font-size: 21px;
  }

  p {
    font-size: 12px;
  }



  .info-modal {
    margin-top: -120px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 15px;
    padding-top: 45px;
    width: 65%;
    font-style: italic;
  }


}

/* End media 600px */
