.btn {
    width: 100px;
    height: fit-content;
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 500;
}

img.project-img {
    width: 700px;
    margin-bottom: 25px;

}


img.rick-img {
    margin-top: -25px;
    width: 755px;
}


img.img-github {
    width: 820px;

}

.btn-ctr {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}

h3 {
    font-family: 'DoHyeon';
    /* color: #76c1d4; */
    font-weight: 800;
    color: #09868b;
    margin-bottom: 10px;
    font-size: 35px;
    font-size: 30px;

}

a {
    text-decoration: none;
}

button.btn.demo-btn {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 25px;
    font-weight: 700;
    width: fit-content;
}

@media(max-width:900px) {
    img.project-img {
        width: 500px
    }

    img.rick-img {
        width: 555px;
    }


    img.img-github {
        width: 600px;

    }
}

@media(max-width:600px) {
    img.project-img {
        width: 400px
    }

    img.rick-img {
        width: 455px;
    }


    img.img-github {
        width: 500px;

    }
}

@media(max-width:450px) {
    img.project-img {
        width: 300px
    }

    img.rick-img {
        width: 355px;
    }


    img.img-github {
        width: 400px;

    }
}