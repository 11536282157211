.resume-cta {
    /* display: none; */
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    font-family: "DoHyeon";
}

.summary-about {
    font-size: 21px;
    padding-top: 5px;
}

button.pdf_btn {
    width: 100px;
    height: 35px;
    font-size: 15px;
    margin-top: 32px;
}



a.about-projects-link {
    color: gray;
    font-size: 21px;
}

a.about-projects-link:visited {
    color: black;
}

a.about-projects-link:hover {
    color: #09868b;
}

@media(max-width: 900px) {
    .resume-cta {
        display: flex;
        margin-top: 21px;
        padding-bottom: 21px;
    }

    button.pdf_btn {
        /* display: none; */
    }

    .summary-about {
        padding-top: 25px;
    }
}

@media(max-width:600px) {
    .summary-about {
        font-size: 18px;
    }
    .about-projects-link {
        font-size: 12px;
    }

    h3.about-project{
        font-size: 21px;
    }

    h3.about-name {
        font-size: 21px;
    }

    a.about-projects-link {
        font-size: 18px;
    }

    p.about-contact{
        font-size: 18px;
    }
}