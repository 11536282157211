div.header-ctr {
    display: flex;
    height: 85px;
    background-color: #76c1d4;
    align-items: center;
    justify-content: space-evenly;


}

.nav-link-wrap {
    display: flex;
    width: 65%;
    justify-content: space-around;

}


/* start if image specs */

.kslogo {
    height: 55px;

}

.social-links-wrap {
    display: flex;
    height: 45px;
    justify-content: center;
    align-items: center;

}

.nav-icon {
    height: 25px;
    background-color: white;
    margin: 15px;
    border-radius: 5px;
}



/* Start of nav bar navigation */

.nav-link {
    display: flex;
    justify-content: center;
    margin: 0 3px;
    height: 55px;
    align-items: center;
    color: black;
    text-decoration: none;
    font-size: 22px;
    font-weight: 600;
    /* text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
        0px -5px 35px rgba(255, 255, 255, 0.3); */
}

.link-active {
    color: #f7f7f7;
    border-bottom: 4px solid #09868b;
    font-weight: 300;

}



.nav-link:hover {
    color: f7f7f7;
    /* border: 1px solid white; */
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 4px solid #09868b;
}

/* .nav-link:active {
    color: #f7f7f7;
    
    border: 1px groove #09868b;
    border-bottom: 4px solid #09868b;
    
    
     } */

@media(max-width:750px) {
       .kslogo {
           height: 55px;
           margin-right: 15px;
           margin-left: 25px;
           margin-top: 5px;
       }
    .social-links-wrap {
        display: none;

    }

    .nav-link-wrap {
        width: 100%;
        margin-right: 25px;

    }
}

@media(max-width:600px) {
    .kslogo {
        height: 45px;
        margin-right:15px;
        margin-left: 25px;
        margin-top: 5px;
    }

    .nav-link-wrap {
        /* flex-direction: column; */
    }

    .header-ctr {
        display: flex;
        /* flex-direction: column; */
        height: 85px;
      


    }

    .logo-nav-ctr {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

    }

    .nav-link {
        font-size: 18px;
        font-family: DoHyeon;

    }

    .link-active {
        font-size: 18px;
    }

    .social-links-wrap {
        display: none;
    }

}

/* End 600px media */