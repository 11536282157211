.cta-wrap {
  background-image: url("../img/penguin-center.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-h2 {
  width: 100%;
  padding: 15px 0;
  margin: 0;
}

.field-cta {
  display: flex;
  flex-direction: column;
  margin: 25px;
  font-size: 21px;
}

.contact-form-cta {
  display: flex;
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button {
  margin-right: 20px;
}

input {
  width: 250px;
  padding: 7px;
  font-size: 18px;
  font-family: "DoHyeon";
  font-weight: 400;
}

textarea {
  width: 250px;
  height: 150px;
  padding: 7px;
  font-size: 18px;
  font-family: "DoHyeon";
  font-weight: 400;
}

label {
  display: none;
}

@media (max-width: 900px) {
  div.dXlIOw {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  form.contact-form {
    width: 595px;
  }
  .cta-wrap {
    background-image: url("../img/penguin-center-sm.jpg");

    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 600px) {
  button {
    margin: 0 auto;
  }
  form.contact-form {
    width: fit-content;
  }

  h2.contact-h2 {
    margin-top: -1px;
    margin-bottom: 5px;
    display: inline;
  }
}
