.footer-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 4px solid #09868b;
    background-color: #76c1d4;
    width: 100%;
    height: 57px;
    font-size: 15px;
    text-align: center;
    font-family: 'DoHyeon';
    font-weight: 100;
}

a.footer-email {
    font-weight: 100;
    font-family: 'DoHyeon';
    
}



 
@media(max-width:600px) {
    .footer-ctr {
        font-size: 14px;
       border: none;
        background-color: #76c1d4;
        }
        a.footer-email {
            font-weight: 100;
            font-family: 'DoHyeon';

        }

}