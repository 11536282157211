.quote-generator {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: left;
    color: black;
    font-family: 'DoHyeon';
     text-shadow: 1px 1px 1px white;
     font-size: 25px;

}

@media(max-width:900px) {

    .quote-generator {
       color: black;
       font-size: 21px;

    }

}
@media(max-width:600px) {

    .quote-generator {
        display: none;
    }

}